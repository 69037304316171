<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link class="active" :to="{ name: 'ListBlogNetworks' }"
                    >Danh sách blogs</router-link
                  >
                </li>
                <li
                  class="breadcrumb-item active"
                  style="display: inline-block"
                >
                  <span aria-current="location"
                    ><span>{{ blog.domain }}</span></span
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <a
            class="btn base-button btn-neutral btn-sm"
            :href="blog.wp_auto_login"
            target="blank"
          >
            <i class="fas fa-sign-in-alt fa-fw"></i> Log in
          </a>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="mb-0">Blog Info</h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr>
                    <th class="w-40">Status</th>
                    <td>
                      <span
                        class="fa fa-2x5 fa-chevron-circle-up text-success fa-fw"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Blog is Online"
                      ></span>
                      <span class="hidden">{{ blog.state }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Domain</th>
                    <td>
                      <span
                        class="fa fa-2x5 fa-lock fa-fw success"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="HTTPS Enabled"
                      ></span>
                      <a
                        href="https://nicosphere.net"
                        rel="noopener noreferrer"
                        target="blank_"
                        >{{ blog.domain }}</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Blog Username</th>
                    <td>
                      <b>{{ blog.wp_admin_username }}</b>
                    </td>
                  </tr>
                  <tr>
                    <th>Blog Password</th>
                    <td>
                      {{ blog.wp_admin_password }}
                    </td>
                  </tr>

                  <tr>
                    <th>Added</th>
                    <td>
                      <time class="datetime-static-long">{{
                        blog.created | formatToLocaleDatetime
                      }}</time>
                    </td>
                  </tr>
                  <tr>
                    <th>Network</th>
                    <td>{{ blog.network.title }}</td>
                  </tr>

                  <tr>
                    <th>Hosting Provider</th>
                    <td>{{ blog.server.provider.title }}</td>
                  </tr>

                  <tr>
                    <th>Blog IP</th>
                    <td>{{ blog.ip }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="mb-0">Blog Health</h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr>
                    <th>Last post</th>
                    <td>
                      <time
                        class="timeago"
                        datetime="2020-12-04T10:04:01Z"
                        title="2020-12-04T10:04:01Z"
                        >{{
                          blog.stats.last_post | formatToLocaleDatetime
                        }}</time
                      >
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Number of comments</th>
                    <td>{{ blog.stats.comments }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Number of pages</th>
                    <td>{{ blog.stats.pages }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Number of posts</th>
                    <td>{{ blog.stats.posts }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Not found indexed URLs</th>
                    <td></td>
                    <td>
                      <span
                        v-if="
                          blog.stats.not_found_pages &&
                          blog.stats.not_found_pages.length == 0
                        "
                        class="fa fa-2x5 fa-check-circle text-success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="No problems detected."
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <th>Redirected indexed URLs</th>
                    <td></td>
                    <td>
                      <span
                        v-if="
                          blog.stats.redirects &&
                          blog.stats.redirects.length == 0
                        "
                        class="fa fa-2x5 fa-check-circle text-success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="No problems detected."
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <th>Traffic</th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.stats.traffic_bytes"
                        class="fa fa-2x5 fa-check-circle text-success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="No problems detected."
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <th>Score</th>
                    <td>{{ blog.stats.health_score }} %</td>
                    <td>
                      <span
                        v-if="blog.stats.health_score_color == 'success'"
                        :class="`fa fa-2x5 fa-check-circle text-${blog.stats.health_score_color}`"
                      ></span>
                      <span
                        v-if="blog.stats.health_score_color == 'danger'"
                        :class="`fa fa-2x5 fa-times-circle text-${blog.stats.health_score_color}`"
                      ></span>
                      <span
                        v-if="blog.stats.health_score_color == 'warning'"
                        :class="`fa fa-2x5 fa-exclamation-triangle text-${blog.stats.health_score_color}`"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="mb-0">DNS info</h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr>
                    <th class="w-40">DNS configured</th>
                    <td>
                      <span
                        v-if="blog.dns_configured"
                        class="fa fa-2x5 fa-check-circle fa-fw text-success"
                      ></span>
                      <span
                        v-else
                        class="fa fa-2x5 fa-times-circle fa-fw text-danger"
                      ></span>
                    </td>
                  </tr>

                  <tr>
                    <th>Nameservers</th>
                    <td>
                      <ul>
                        <li
                          v-for="(nameserver, index) in blog.nameservers"
                          :key="index"
                        >
                          {{ nameserver }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>DNS report</th>
                    <td>
                      <a
                        :href="`http://viewdns.info/dnsreport/?domain=${blog.domain}`"
                        rel="noopener noreferrer"
                        target="_blank"
                        >ViewDNS</a
                      >
                      <a
                        :href="`https://who.is/whois/${blog.domain}`"
                        rel="noopener noreferrer"
                        target="_blank"
                        >who.is</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="mb-0">SEO Metrics</h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tbody>
                  <tr>
                    <th class="w-85 w-to-lg-40">Indexed</th>
                    <td>
                      <span
                        v-if="blog.indexation == 'indexed'"
                        class="fa fa-2x5 fa-check-circle fa-fw text-success timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last update: about 11 hours ago"
                      ></span>
                      <span
                        v-else
                        class="fa fa-2x5 fa-times-circle fa-fw text-danger timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last update: about 11 hours ago"
                      ></span>
                    </td>
                  </tr>
                  <tr>
                    <th>Majestic Trust Flow</th>
                    <td>
                      <span
                        class="timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title=""
                        data-original-title="Last update: 8 days ago"
                      >
                        {{ blog.majestic_metrics.trust_flow }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Majestic Citation Flow</th>
                    <td>
                      <span
                        class="timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last update: 8 days ago"
                      >
                        {{ blog.majestic_metrics.citation_flow }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Majestic External Backlinks</th>
                    <td>
                      <span
                        class="timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last update: 8 days ago"
                      >
                        {{ blog.majestic_metrics.external_backlinks }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>Majestic Referring IPs</th>
                    <td>
                      <span
                        class="timeago"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last update: 8 days ago"
                      >
                        {{ blog.majestic_metrics.referring_ips }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div v-if="blog.validator" class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header">
              <h3 class="mb-0">Blog Validator</h3>
            </div>

            <div class="card-body">
              <table class="table">
                <!-- Check Domain Expired -->
                <tbody>
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Domain Status</p>
                      </div>
                      <small style="color: gray">Is the domain expired.</small>
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.domain_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:29.433272Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:29.433272Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Nameservers -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Nameservers Status</p>
                      </div>
                      <small style="color: gray"
                        >Are the nameservers correctly set.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.nameservers_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:32.662384Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:32.662384Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Zonefile -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Zonefile Records Status</p>
                      </div>
                      <small style="color: gray"
                        >Are the zonefile records correctly set.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.zonefile_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:30.194016Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>
                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:30.194016Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Blog Deployment -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Blog Deployment Status</p>
                      </div>
                      <small style="color: gray"
                        >Was the deployment to the server successful.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.deployment_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:30.387278Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:30.387278Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Server -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Server Status</p>
                      </div>
                      <small style="color: gray"
                        >Is the blog server online.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.server_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:29.585707Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>
                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:29.585707Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Availability -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Browser Accessibility</p>
                      </div>
                      <small style="color: gray"
                        >Is the blog accessible with a browser.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.availability_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:29.943095Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:29.943095Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check Backups -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">Recent Backup Status</p>
                      </div>
                      <small style="color: gray"
                        >Have the backups been run in the last 3 days.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.backups_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:29.613058Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:29.613058Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>

                  <!-- Check SEO Data -->
                  <tr>
                    <th>
                      <div
                        class="d-flex align-items-baseline justify-content-between"
                      >
                        <p class="p-0 m-0">SEO Data Status</p>
                      </div>
                      <small style="color: gray"
                        >Was the SEO data recently updated.</small
                      >
                    </th>
                    <td></td>
                    <td>
                      <span
                        v-if="blog.validator.seo_valid"
                        class="timeago"
                        datetime="2022-09-28T08:44:30.272996Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-check-circle text-success"
                        ></span>
                      </span>

                      <span
                        v-else
                        class="timeago"
                        datetime="2022-09-28T08:44:30.272996Z"
                        data-title-format="Last check: {{}}"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Last check: about 7 hours ago"
                      >
                        <span
                          class="fa fa-2x5 fa-times-circle text-danger"
                        ></span>
                      </span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatToLocaleDatetime, formatTime } from "@/utils";
export default {
  name: "BlogNetworkDetail",
  data() {
    return {
      blog: null,
    };
  },
  filters: {
    formatToLocaleDatetime(dateTime) {
      return formatToLocaleDatetime(dateTime);
    },
    formatTime(dateTime) {
      return formatTime(Date.parse(dateTime) / 1000);
    },
  },
  created() {
    this.getBlogNetworksDetail();
  },
  methods: {
    async getBlogNetworksDetail() {
      this.$preloaders.open();

      try {
        const data = await this.$store.dispatch(
          "blognetworks/getBlogNetworksDetail",
          this.$route.params.id
        );
        this.blog = data;
      } catch (err) {
        console.log(err);
      }

      this.$preloaders.close();
    },
  },
  blogLogin() {
    window.location.href = this.blog.wp_auto_login;
  },
};
</script>
